import * as React from "react"

import whatsAppImg from '../images/WhatsApp.svg';

import inmovallesBirdImg from '../images/inmovalles_bird.svg';
import inmovallesTitleImg from '../images/inmovalles_title.svg';

export default function Nav() {
  return (
    <nav className="flex flex-col justify-center md:justify-between items-center md:p-12 md:flex-row lg:p-4">
      <ul className="flex text-center justify-content-center pt-8 md:p-4">
        <li>
          <a href="/">
            <div className="flex">
              <img src={inmovallesBirdImg} className="box-content h-20 w-20 md:box-content md:h-14 md:w-14"  />
              <img src={inmovallesTitleImg} className="hidden px-1 box-content w-32 md:block" />
            </div>
          </a>
        </li>
      </ul>
      <ul className="p-4 md:px-2">
        <li>
          <a className="flex items-center text-whatsapp" href="https://wa.me/593984530308?text=Saludos%2C%0A%0ANecesito%20asistencia%20en%20bienes%20ra%C3%ADces.%0A">
            <img src={whatsAppImg} className="box-content w-10 h-10" />
            098 453 0308
          </a>
        </li>
      </ul>
    </nav>
  )
}
