import React from "react";
import { StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import whatsAppImg from '../images/WhatsApp.svg'

export const query = graphql`
query AgentsQuery {
  allContentfulAgent(sort: {order: ASC, fields: createdAt}) {
    edges {
      node {
        id
        firstName
        lastName
        phoneNumber
        profileImage {
          fluid {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
}
`

export function AgentsContainer() {
    return (
        <StaticQuery
            query={ query }
            render={ data => (
                <div>
                    <h1 className="text-4xl tracking-tight font-bold m-4">
                        <span className="block text-blue text-center lg:text-left"> Conoce nuestro equipo </span>
                    </h1>
                    <div className="flex flex-wrap my-4 self-center justify-center">
                        {data.allContentfulAgent.edges.map(edge => <Agent key={edge.node.id} {...edge.node} />)}
                    </div>
                </div>
            )}
        />
    )
}

const formatPhoneNumber = pn => {
    return `+${pn.substring(0, 3)} ${pn.substring(3, 5)} ${pn.substring(5, 8)} ${pn.substring(8)}`;
}

export default function Agent({ firstName, lastName, phoneNumber, profileImage }) {
    return (
        <a className="bg-white text-center w-64 m-4 p-8 rounded-xl shadow-lg hover:cursor-pointer hover:shadow-2xl" href={`https://wa.me/${phoneNumber}?text=Saludos%2C%0A%0ANecesito%20asistencia%20en%20bienes%20ra%C3%ADces.%0A`}>
            <Img fluid={profileImage.fluid} alt="User avatar" className="ring-2 ring-blue-800 w-40 h-40 object-cover ring-offset-2 rounded-full mx-auto shadow-lg" />
            
            <div className="mt-4 text-blue">
                <h2 className="font-semibold text-2xl">{firstName}</h2>
                <h2 className="text-2xl">{lastName}</h2>
            </div>
            <div className="flex items-center text-whatsapp">
                <img  src={whatsAppImg} className="box-content w-10 h-10" />
                {formatPhoneNumber(phoneNumber)}
            </div>
        </a>
    );
}