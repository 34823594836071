import * as React from "react"

import properatiLogo from '../images/properati-negro.svg'

import Nav from '../components/nav'
import { AgentsContainer } from '../components/Agent';

function MainTitle() {
  return (
    <div className="text-center">
      <h1 className="text-4xl tracking-tight font-extrabold">
        <span className="block text-blue xl:inline">INMOVALLES </span>
        <span className="block text-blue xl:inline font-light text-4xl"> Asociados</span>
        <span className="block text-yellow-500">Alcanza tus sueños</span>
      </h1>
      <p className="mt-3 text-base text-gray-500">
        Servicios profesionales en bienes raíces. 
      </p>  
    </div>
  )
}

function PlusValiaButton() {
  return (
    <div className="m-8 transition-all ring-2 ring-yellow-500 shadow-lg hover:shadow-2xl">
      <a className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md md:py-4 md:text-lg md:px-10" href="https://www.properati.com.ec/inmobiliaria/d56a934e-9988-4d0d-961e-c3938ec688af" target="_blank" rel="nofollow">
        <span>
          Nuestras propiedades en
          <div>
            <img src={properatiLogo} width="128"/>
          </div>
        </span>
      </a>
    </div>
  );
}

function Header() {
  return (
    <main className="mx-auto max-w-7xl px-4">
      <MainTitle />
      <div className="flex justify-center">
        <PlusValiaButton />
      </div>
    </main>
  )
}

const IndexPage = () => (
  <main className="flex flex-col items-center">
    <div className="w-full lg:max-w-6xl">
      <Nav />
      <Header />
      {/* <BackgroundImage /> */}
    </div>
    <div className="w-full bg-gray-100">
      <div className="flex m-auto lg:max-w-6xl">
        <AgentsContainer />
      </div>
    </div>
  </main>
)

export default IndexPage
